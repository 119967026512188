
import { defineComponent, ref, reactive, watch } from 'vue';
import { SetupStates } from './setup-states';
import { getGeotabApi } from '@/api/geotab';

const defaultSecurityGroupNames: Record<string, string> = {
  GroupEverythingSecurityId: 'Administrator',
  GroupSupervisorSecurityId: 'Supervisor',
  GroupUserSecurityId: 'User',
  GroupViewOnlySecurityId: 'View Only',
  GroupNothingSecurityId: 'Nothing',
  GroupDriveUserSecurityId: 'Drive App User',
};

export default defineComponent({
  emits: ['next'],
  setup(props, context) {
    const selectedSecurityGroups = ref<string[]>(['GroupEverythingSecurityId']);

    watch(selectedSecurityGroups, value =>
      console.log('Security groups: ', value),
    );

    const onNext = () =>
      context.emit('next', SetupStates.UserSync, selectedSecurityGroups.value);

    const securityGroups = reactive<any>([]);
    const isLoadingSecGroups = ref(true);

    getGeotabApi().then(api => {
      api
        .call('Get', {
          typeName: 'Group',
          search: {
            id: 'GroupSecurityId',
          },
        })
        .then((secGroups: any) => {
          console.log('Security groups:', secGroups);
          const filtered = secGroups.filter(
            (group: any) => group.id !== 'GroupSecurityId',
          );
          securityGroups.push(...filtered);
          isLoadingSecGroups.value = false;
        });
    });

    return {
      selectedSecurityGroups,
      onNext,
      defaultSecurityGroupNames,
      securityGroups,
      isLoadingSecGroups,
    };
  },
});
