
import { defineComponent, computed, ref, reactive } from 'vue';
import WelcomeComp from '@/components/geotab-setup/Welcome.vue';
import TermsComp from '@/components/geotab-setup/Terms.vue';
import OrgComp from '@/components/geotab-setup/OrgSetup.vue';
import UserSyncComp from '@/components/geotab-setup/SyncUsers.vue';
import OnboardDriversComp from '@/components/geotab-setup/OnboardDrivers.vue';
import DoneComp from '@/components/geotab-setup/Done.vue';
import { SetupStates } from '@/components/geotab-setup/setup-states';
import { ElLoading, ElMessage } from 'element-plus';
import { signupWithGeotab } from '@/api/accidentplan-api';
import { getGeotabAuth } from '@/helpers/get-geotab-auth';
import { AuthModule } from '@/store/auth';

const components = [
  WelcomeComp,
  TermsComp,
  OrgComp,
  UserSyncComp,
  OnboardDriversComp,
  DoneComp,
];

export default defineComponent({
  name: 'GeotabSetupView',

  setup() {
    const currentState = ref<SetupStates>(SetupStates.Welcome);
    const currentComp = computed(() => components[currentState.value]);

    const setupConfig = reactive({
      securityClearances: [],
      orgProps: { name: 'default_name' },
    });

    const onNext = async (state: SetupStates, value: any) => {
      switch (state) {
        case SetupStates.Welcome: {
          break;
        }

        case SetupStates.Terms: {
          break;
        }

        case SetupStates.OrgSetup: {
          // in this case the value is the org properties
          // that the user selected
          setupConfig.orgProps = value;
          break;
        }

        case SetupStates.UserSync: {
          // in this case the value is the security
          // clearance that the user selected
          setupConfig.securityClearances = value;
          console.log(setupConfig);
          break;
        }

        case SetupStates.DriverOnboarding: {
          break;
        }

        case SetupStates.Done: {
          const loading = ElLoading.service({
            text: 'Creating your new AccidentPlan account...',
          });

          const auth = await getGeotabAuth();

          if (auth.credentials.sessionId && auth.path)
            auth.credentials.type = 'session';

          try {
            await signupWithGeotab({
              auth,
              requiredSecurityClearances: setupConfig.securityClearances,
              org: setupConfig.orgProps,
            });

            loading.close();
            AuthModule.checkAuth();
            return;
          } catch (error) {
            console.error(error);

            loading.close();
            ElMessage.error({
              type: 'error',
              message: 'Sorry, an error occurred while creating your account.',
            });
          }
        }
      }

      console.log('Value from setup comp:', value);
      currentState.value += 1;
    };

    return { currentComp, onNext, currentState };
  },
});
