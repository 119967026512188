
import { defineComponent } from 'vue';
import { SetupStates } from './setup-states';

export default defineComponent({
  emits: ['next'],
  setup(props, context) {
    const onNext = () => context.emit('next', SetupStates.Terms);

    return { onNext };
  },
});
