
import { defineComponent, ComputedRef, computed } from 'vue';
import { SetupStates } from './setup-states';
import { useRoute } from 'vue-router';

export default defineComponent({
  name: 'SetupDoneComp',
  emits: ['next'],
  setup(props, context) {
    const route = useRoute();
    const onNext = () => context.emit('next', SetupStates.Done);

    // check if we're in the Geotab setup test mode
    const isInGeotabSetupTestMode: ComputedRef<boolean> = computed(
      () => route.query.geotab_setup_test_mode === 'true',
    );

    return { onNext, isInGeotabSetupTestMode };
  },
});
