
import { defineComponent, ref } from 'vue';
import { SetupStates } from './setup-states';
import { useGeotabOrgForm } from '@/composables/useGeotabOrgForm';
import { FetchStates } from '@/helpers/fetch-states';

export default defineComponent({
  name: 'OrgSetup',
  emits: ['next'],
  setup(props, context) {
    const formRef = ref(null);
    const geotabOrgForm = useGeotabOrgForm(formRef);

    const onNext = () =>
      context.emit('next', SetupStates.OrgSetup, geotabOrgForm.form.value);

    return {
      formRef,
      ...geotabOrgForm,
      onNext,
      FetchStates,
    };
  },
});
