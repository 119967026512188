import { isOrgNameTaken } from '@/api/accidentplan-api';
import { CreateSingleOrgDTO } from '@/api/dto/org';
import { getGeotabDefaultCreateDTO } from '@/api/geotab';
import { alertError } from '@/helpers/alert-error';
import { FetchStates } from '@/helpers/fetch-states';
import { ref, Ref, watch } from 'vue';

// Generates a validator for making sure an organization name is not taken
const generateValidateName = () => async (
  rule: any,
  value: any,
  callback: any,
) => {
  const isTaken = await isOrgNameTaken(value);

  if (isTaken) {
    callback(new Error('Organization name already taken!'));
  } else {
    callback();
  }
};

// Creates a form for Geotab organization configuration
export function useGeotabOrgForm(formRef: Ref<any>) {
  const form = ref<CreateSingleOrgDTO>({
    name: '',
    address: '',
    address2: '',
    city: '',
    state: '',
    country: '',
    zip: '',
    main_phone: '',
    us_dot_num: '',
  });

  const setOrg = (newOrg: CreateSingleOrgDTO) => {
    form.value.name = newOrg.name;
    form.value.address = newOrg.address ?? '';
    form.value.address2 = newOrg.address2 ?? '';
    form.value.city = newOrg.city ?? '';
    form.value.state = newOrg.state ?? '';
    form.value.country = newOrg.country ?? '';
    form.value.zip = newOrg.zip ?? '';
    form.value.main_phone = newOrg.main_phone ?? '';
    form.value.us_dot_num = newOrg.us_dot_num ?? '';
  };

  const rules = {
    name: [
      { required: true, message: 'Name is required', trigger: 'blur' },
      { validator: generateValidateName(), trigger: 'blur' },
    ],
  };

  const isValid = ref(false);
  const validateForm = async () => {
    isValid.value = await new Promise<boolean>(res =>
      formRef.value.validate((_valid: boolean) => res(_valid)),
    );
  };

  const fetchState = ref<FetchStates>(FetchStates.UNFETCHED);

  const fetchDefaultOrg = async () => {
    fetchState.value = FetchStates.FETCHING;

    try {
      // Getting default org settings from the Geotab organization
      const createOrgDTO = await getGeotabDefaultCreateDTO();
      setOrg(createOrgDTO);

      // Validating all fields
      await validateForm();

      fetchState.value = FetchStates.FETCHED;
    } catch (error) {
      console.error(error);
      alertError(error, 'Failed to fetch default organization properties!');

      fetchState.value = FetchStates.UNFETCHED;
    }
  };

  fetchDefaultOrg();

  return {
    rules,
    form,
    setOrg,
    isValid,
    validateForm,
    fetchState,
    fetchDefaultOrg,
  };
}
